import { http } from '@/plugin/axios'

const state = {
  trainings: {
    meta: {
      current_page: 1,
      from: 1,
      last_page: 1,
      per_page: 10,
      total: 6
    }
  },
  training: [],
  mainPageTraining: []
}
const getters = {
  getTrainings: (state) => state.trainings,
  getMainPageTrainings: (state) => state.mainPageTraining,
  getTraining: (state) => state.training
}
const actions = {
  async fetchTrainings ({ commit }, { searchString, trainingType, page }) {
    return await http({
      url: `/frontend/library?per_page=4&search=${searchString}&type=${trainingType}&page=${page}`,
      method: 'get'
    }).then(({ data }) => {
      commit('setTrainings', data)
    })
  },
  async fetchTraining ({ commit }, id) {
    return await http({
      url: `/frontend/library/${id}`,
      method: 'get'
    }).then(({ data }) => {
      commit('setTraining', data)
    })
  },
  async fetchMainPageTraining ({ commit }, id) {
    return await http({
      url: '/frontend/main-page-library',
      method: 'get'
    }).then(({ data }) => {
      commit('setMainPageTraining', data)
    })
  }
}
const mutations = {
  setTrainings (state, trainingData) {
    state.trainings = trainingData.data
  },
  setTraining (state, trainingData) {
    state.training = trainingData.data
  },
  setMainPageTraining (state, trainingData) {
    state.mainPageTraining = trainingData.data
  }
}

export default {
  namespace: true,
  state,
  getters,
  actions,
  mutations
}
