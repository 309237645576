<template>
  <!--wrap начало-->
  <div id="wrap">
    <header>
      <section class="announce" :class="{ active: !collapseOpen }">
        <div class="container">
          <div class="announce__fl">
            <div class="announce__head">
              <h2 class="announce__title">
                Кайшибаевские чтения · 08-09 октября
              </h2>
              <p>Конференция в честь 60-летия Профессора Кайшибаева Н.С.</p>
            </div>
            <div class="buttons">
              <a href="http://neuro-lessons.kz/" target="_blank" class="button"
                >Регистрация</a
              >
              <button class="collapse-btn" @click="collapse">
                <img src="img/col-arrow.svg" alt="icon" />
              </button>
            </div>
          </div>
          <div class="announce__info">
            <p>
              XI ежегодная научно-практическая онлайн конференция при участии
              международных экспертов.
            </p>
            <div class="dates">
              <p>
                <img src="img/an-calendar.svg" alt="calendar" /> Дата:
                <span>08-09 октября</span>
              </p>
              <p>
                <img src="img/an-time.svg" alt="time" /> Время:
                <span>08:45 - 20:30, 08:50 - 19:00</span>
              </p>
            </div>
          </div>
        </div>
        <div class="announce-person">
          <img src="img/announce-head.svg" alt="person" class="person-head" />
          <img src="img/person-pain.png" alt="person" class="person-pain" />
        </div>
      </section>
    </header>
    <div class="header">
      <div class="container">
        <div class="head-navbar">
          <div class="logo">
            <router-link to="/">
              <img src="./assets/img/logo.svg" alt="logo" />
            </router-link>
          </div>

          <ul class="nav-menu" :class="{ active: active }">
            <img
              src="img/close.png"
              alt="close"
              class="close"
              @click="toggleMenu"
            />
            <li @click="toggleMenu">
              <router-link to="/headaches">Головные боли</router-link>
            </li>
            <li @click="toggleMenu">
              <router-link to="/migraine-pathogenesis"
                >Патогенез мигрени</router-link
              >
            </li>
            <li @click="toggleMenu">
              <router-link to="/clinical-manifestations"
                >Клинические проявления</router-link
              >
            </li>
            <li @click="toggleMenu">
              <router-link to="/diagnostics">Диагностика</router-link>
            </li>
            <li @click="toggleMenu">
              <router-link to="/living-with-migraines"
                >Жизнь с мигренью</router-link
              >
            </li>
            <li @click="toggleMenu">
              <router-link to="/therapy">Лечение</router-link>
            </li>
            <li @click="toggleMenu">
              <router-link to="/training">Обучение</router-link>
            </li>
          </ul>
          <div class="menu-link" @click="toggleMenu">
            <div class="m-text">Меню</div>
            <div class="menu-ico">
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <router-view />

    <footer>
      <div class="container">
        <div class="row">
          <div class="col-md-4">
            <div class="firm-contacts">
              <div class="tel">
                <a href="tel:+77273251615"> +7 (727) 325-16-15</a>
              </div>
              <div class="mail">
                <a href="mailto:info.tevakz@tevapharm.com"
                  >info.tevakz@tevapharm.com</a
                >
              </div>
              <div class="address">
                Республика Казахстан, 050059, г.Алматы, Бостандыкский район,
                проспект <br class="visible-xs" />
                Аль-Фараби, дом 17/1, блок 5Б, 6-й этаж.
              </div>
              <div class="more-info">
                Подробную информацию о препаратах Teva вы можете узнать на нашем
                сайте
                <a href="http://kaz.teva" target="_blank">kaz.teva</a>
              </div>
            </div>
          </div>
          <div class="col-md-5">
            <div class="politics">
              <p class="p1">
                <router-link to="/privacy">
                  Политика конфиденциальности</router-link
                >
              </p>
              <p class="p2">
                <router-link to="/statement">
                  Заявление о порядке обработки персональных данных компанией
                  «Тева»: <br class="visible-xs" />
                  Надзор за безопасностью лекарственных препаратов
                  (фармаконадзор и контроль качества)</router-link
                >
              </p>

              <p class="p3">
                <a
                  href="https://www.kaz.teva/ru/kazakhstan/contact-us/contact-us-form/"
                  target="_blank"
                  >Сообщить о нежелательном явлении</a
                >
              </p>
              <p class="p4">
                <router-link to="/terms-of-use">
                  Условия доступа к интернет-ресурсу и условия пользования
                  интернет-ресурсом</router-link
                >
              </p>
            </div>
          </div>
          <div class="col-md-3">
            <div class="migrain-help">
              <div class="text-inform">
                Более подробная информация о заболевании
                <a href="https://www.migrainehelp.ru" target="_blank"
                  >migrainehelp.ru</a
                >
              </div>
              <div class="social">
                <div class="text">
                  Следите за Teva <br />
                  в соцсетях
                </div>
                <a
                  href="https://www.facebook.com/tevapharm/"
                  target="_blank"
                  class="fb-ico"
                ></a>
                <a
                  href="https://twitter.com/tevapharmeurope"
                  target="_blank"
                  class="twt-ico"
                ></a>
              </div>
            </div>
            <div class="co d-block d-sm-none">NPS-KZ-00186</div>

            <!--                <a href='https://rocketfirm.com/' class='rocket-logo' target='_blank'></a>-->

            <a href="http://rocketfirm.com" class="rocket-link" target="_blank">
              <svg
                class="rocket-logo"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 105 14.47"
              >
                <title>Rocket Firm logo</title>
                <path
                  class="logo-letter"
                  d="M5,11.67L3.28,8.51H1.89v3.16H0V2.78H4.17A3.44,3.44,0,0,1,5.41,3,2.6,2.6,0,0,1,6.95,4.51a3.08,3.08,0,0,1,.21,1.14,3,3,0,0,1-.17,1,2.51,2.51,0,0,1-.45.78A2.54,2.54,0,0,1,5.91,8a2.39,2.39,0,0,1-.71.29l2,3.38H5ZM3.88,6.85a1.43,1.43,0,0,0,1-.32,1.09,1.09,0,0,0,.38-0.88,1.09,1.09,0,0,0-.38-0.88,1.43,1.43,0,0,0-1-.32h-2v2.4h2Z"
                />
                <path
                  class="logo-letter"
                  d="M13.11,11.83a5,5,0,0,1-1.85-.34A4.29,4.29,0,0,1,8.82,9.08a4.82,4.82,0,0,1-.35-1.85,4.82,4.82,0,0,1,.35-1.85A4.29,4.29,0,0,1,11.26,3,5.25,5.25,0,0,1,15,3a4.29,4.29,0,0,1,2.44,2.41,4.82,4.82,0,0,1,.35,1.85,4.82,4.82,0,0,1-.35,1.85A4.29,4.29,0,0,1,15,11.49,5,5,0,0,1,13.11,11.83Zm0-1.68a2.67,2.67,0,0,0,1.11-.23,2.6,2.6,0,0,0,.85-0.61,2.73,2.73,0,0,0,.55-0.92,3.58,3.58,0,0,0,0-2.32,2.8,2.8,0,0,0-.55-0.93,2.46,2.46,0,0,0-.85-0.61,2.93,2.93,0,0,0-2.23,0,2.39,2.39,0,0,0-.85.61,2.85,2.85,0,0,0-.54.93,3.57,3.57,0,0,0,0,2.32,2.77,2.77,0,0,0,.54.92,2.52,2.52,0,0,0,.85.61A2.67,2.67,0,0,0,13.11,10.15Z"
                />
                <path
                  class="logo-letter"
                  d="M23.67,11.83a5.15,5.15,0,0,1-1.87-.33,4.35,4.35,0,0,1-2.5-2.39,4.66,4.66,0,0,1-.37-1.87,4.66,4.66,0,0,1,.37-1.87A4.35,4.35,0,0,1,21.8,3a5.15,5.15,0,0,1,1.87-.33A4.34,4.34,0,0,1,25,2.82a4.14,4.14,0,0,1,1,.49,3.68,3.68,0,0,1,.79.69,5.6,5.6,0,0,1,.57.81l-1.63.8a2.43,2.43,0,0,0-.84-0.92,2.21,2.21,0,0,0-1.25-.37,2.8,2.8,0,0,0-1.11.22,2.63,2.63,0,0,0-.89.61,2.79,2.79,0,0,0-.58.93,3.19,3.19,0,0,0-.21,1.16,3.23,3.23,0,0,0,.21,1.17,2.71,2.71,0,0,0,.58.93,2.68,2.68,0,0,0,.89.61,2.8,2.8,0,0,0,1.11.22,2.24,2.24,0,0,0,1.25-.37,2.36,2.36,0,0,0,.84-0.93l1.63,0.79a5.54,5.54,0,0,1-.57.81,3.87,3.87,0,0,1-.79.7,4,4,0,0,1-1,.49A4.34,4.34,0,0,1,23.67,11.83Z"
                />
                <path
                  class="logo-letter"
                  d="M34.53,11.67L31.84,8.11l-0.69.83v2.73H29.25V2.78h1.89v4l3.15-4h2.33L33.09,7l3.77,4.72H34.53Z"
                />
                <path
                  class="logo-letter"
                  d="M38.53,11.67V2.78h6.29V4.45h-4.4V6.33h4.3V8h-4.3v2h4.4v1.67H38.53Z"
                />
                <path
                  class="logo-letter"
                  d="M49.21,11.67V4.45h-2.6V2.78h7.08V4.45H51.11v7.22H49.21Z"
                />
                <path
                  class="logo-letter"
                  d="M73.56,11.67V2.78h6.29V4.45h-4.4V6.33h4.3V8h-4.3v3.68H73.56Z"
                />
                <path class="logo-letter" d="M82,11.67V2.78h1.89v8.89H82Z" />
                <path
                  class="logo-letter"
                  d="M91.18,11.67L89.43,8.51H88v3.16H86.15V2.78h4.17A3.44,3.44,0,0,1,91.57,3,2.6,2.6,0,0,1,93.1,4.51a3.08,3.08,0,0,1,.21,1.14,3,3,0,0,1-.17,1,2.51,2.51,0,0,1-.45.78,2.54,2.54,0,0,1-.63.53,2.39,2.39,0,0,1-.71.29l2,3.38H91.18ZM90,6.85a1.43,1.43,0,0,0,1-.32,1.09,1.09,0,0,0,.38-0.88A1.09,1.09,0,0,0,91,4.77a1.43,1.43,0,0,0-1-.32H88v2.4h2Z"
                />
                <path
                  class="logo-letter"
                  d="M102.92,11.67V5.45l-2.51,6.22H99.59L97.08,5.45v6.22H95.19V2.78h2.65L100,8.16l2.16-5.37h2.66v8.89h-1.89Z"
                />
                <path
                  class="logo-icon"
                  d="M70.57,7.23a7.24,7.24,0,1,0-7.24,7.24A7.23,7.23,0,0,0,70.57,7.23ZM61.75,12c-0.36.07-.32-0.1-0.21-0.25l0.15-.17c0.77-.87,1.37-1.19.37-1.19s-1.32,0-1.59-.28h0a2.62,2.62,0,0,1-.28-1.59c0-1-.32-0.4-1.2.37L58.81,9c-0.15.11-.32,0.15-0.25-0.21A5.23,5.23,0,0,1,60,6.14a12,12,0,0,1,2.38-.89c2.29-1.65,4.48-2.31,4.95-2s-0.32,2.65-2,4.95a12,12,0,0,1-.89,2.38A5.23,5.23,0,0,1,61.75,12Z"
                />
                <path
                  class="logo-icon"
                  d="M65.08,6.78A1.24,1.24,0,0,1,63.79,8,1.25,1.25,0,1,1,65.08,6.78Z"
                />
              </svg>
              <span class="logo-year">2021</span></a
            >
          </div>
        </div>
        <div class="co hidden-xs">NPS-KZ-00186</div>
      </div>
    </footer>
    <div class="cookies" :class="{ hidden: isCookieEnabled }">
      <div class="container">
        <div class="desc">
          <div class="text">
            Данный Сайт использует cookie-файлы в целях предоставления вам
            лучшего пользовательского опыта на нашем Сайте.<br />
            Продолжая использовать данный Сайт, <br class="visible-xs" />
            вы соглашаетесь с использованием нами cookie-файлов.<br
              class="hidden-xs"
            />
            Для получения дополнительной информации см. наше
            <br class="visible-xs" />
            <a href="#">Уведомление о конфиденциальности и файлах cookie</a>
          </div>
          <button class="button-link js-cookies-btn" @click="cookieHide()">
            Ок
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- /wrap конец-->
</template>

<script>
import Cookies from 'js-cookie'

export default {
  data: function () {
    return {
      active: false,
      collapseOpen: false
    }
  },
  computed: {
    isCookieEnabled () {
      return Cookies.get('teva-cookie')
    }
  },
  methods: {
    toggleMenu () {
      this.active = !this.active
    },
    collapse () {
      this.collapseOpen = !this.collapseOpen
    },
    cookieHide () {
      Cookies.set('teva-cookie', true)
    }
  },
  mounted () {
    const announce = document.querySelector('.announce')

    if (window.location.href.indexOf('https://migraine.tevaneuro.kz/') > -1) {
      announce.style.display = 'none'
    }

    if (window.location.href !== 'https://teva-ajovy.rocketfirm.net/') {
      if (document.referrer === 'https://tevaneuro.kz/') {
        sessionStorage.setItem('redirect', '1')
        console.log(document.referrer + ' ref ')
      }

      if (sessionStorage.getItem('redirect') !== '1') {
        window.location.href = 'https://tevaneuro.kz/'
      }

      sessionStorage.setItem('redirect', '1')
    }
  }
}
</script>

<style>
@media (min-width: 600px) {
  .head-navbar .nav-menu .close {
    display: none;
  }
}

.header {
  background: #fff;
  position: sticky;
  top: 0;
  z-index: 50;
  box-shadow: 0 0 20px rgb(0 0 0 / 15%);
}
a.router-link-exact-active {
  color: #00567a;
}

footer a.router-link-exact-active {
  color: #afcb37;
}
.announce {
  background: #00567a;
  color: #fff;
  padding: 20px 0;
  position: relative;
  overflow: hidden;
  font-family: notosans-light;
  font-size: 16px;
}

@media (max-width: 600px) {
  .announce {
    padding: 13px 0;
    transition: 0.8s;
  }

  .announce.active {
    padding: 15px 0 45px;
  }
}

.announce__fl {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  position: relative;
}
.announce__title {
  color: #fff;
  font-size: 26px;
  margin-bottom: 0.35rem;
  font-family: notosans-medium;
}

@media (max-width: 1100px) {
  .announce__title {
    font-size: 24px;
  }
}

@media (max-width: 800px) {
  .announce__title {
    font-size: 20px;
    width: 90%;
  }
}

@media (min-width: 600px) {
  .sm-hidden {
    display: none;
  }
}

@media (max-width: 800px) {
  .announce__title {
    line-height: normal;
    margin-bottom: 0;
  }
}

.announce__info {
  width: 62%;
  line-height: 170%;
  max-height: 0px;
  padding: 0;
  opacity: 0;
  transition: 0.8s;
}

.announce.active .announce__info {
  padding-top: 28px;
  opacity: 1;
  max-height: 500px;
}
.announce__info .dates {
  margin-top: 22px;
}
.announce__info p {
  font-size: 20px;
}

@media (max-width: 800px) {
  .announce__info {
    width: 100%;
  }
  .announce__info p {
    font-size: 16px;
    line-height: normal;
  }

  .announce.active .announce__info {
    padding-top: 15px;
  }
}
.dates p {
  display: flex;
  align-items: center;
  font-family: notosans-medium;
  font-size: 16px;
  margin-bottom: 0;
}

.dates p img {
  margin-right: 0.438rem;
}

.dates p span {
  font-family: notosans-light;
  margin-left: 5px;
}
.announce__head .buttons {
  position: relative;
  z-index: 5;
}
.announce__head p {
  margin: 0;
  opacity: 0.6;
}

@media (max-width: 800px) {
  .announce__head {
    width: 100%;
  }
  .announce__head p {
    font-size: 14px;
  }
}

@media (max-width: 600px) {
  .announce__head p {
    opacity: 0;
    max-height: 0px;
    transition: 0.3s;
  }

  .active .announce__head p {
    max-height: 100px;
    opacity: 0.6;
  }

  .announce__info .dates {
    margin-top: 45px;
  }
}

.buttons .button {
  color: #003f77;
  font-size: 16px;
}

@media (max-width: 800px) {
  .buttons {
    margin-top: 15px;
  }
  .collapse-btn {
    position: absolute;
    right: 0;
    top: 10px;
  }
}

@media (max-width: 600px) {
  .buttons .button {
    font-size: 12px;
    display: inline-block;
  }
}

.buttons .button:hover {
  background-color: #aa198d;
  color: #fff;
}
.collapse-btn {
  background: none;
  border: none;
  outline: none;
  margin-left: 35px;
  transition: 0.3s;
}

.announce.active .collapse-btn {
  transform: rotate(180deg);
}

.announce-person {
  position: absolute;
  right: 25%;
  bottom: -150%;
  display: flex;
  justify-content: center;
  opacity: 0;
  transition: 0.8s;
}

.announce.active .announce-person {
  opacity: 1;
  bottom: 0;
}

img.person-pain {
  position: absolute;
  top: 16px;
  left: 45px;
  animation: shadow 1s linear infinite alternate-reverse;
}

@media (max-width: 1100px) {
  .announce-person {
    right: 15%;
  }
}

@media (max-width: 800px) {
  .announce-person {
    right: 10px;
    height: 123px;
    width: 111px;
  }
  img.person-head {
    width: 100%;
    height: 100%;
    top: 0;
  }
  img.person-pain {
    width: 57px;
    left: 25px;
  }
}

@keyframes shadow {
  from {
    transform: scale(1);
  }
  to {
    transform: scale(1.3);
  }
}
</style>
