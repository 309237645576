import axios from 'axios'
import Cookies from 'js-cookie'

const http = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL,
  timeout: 60000
})

http.interceptors.request.use(
  (config) => {
    const token = Cookies.get('teva-user-token')
    if (token) {
      config.headers.Authorization = `Bearer ${token}`
    }
    const lang = Cookies.get('teva-user-language')
    if (lang) {
      config.headers['Accept-Language'] = lang
    } else {
      config.headers['Accept-Language'] = 'ru'
      Cookies.set('teva-user-language', 'ru')
    }

    return config
  },
  (error) => {
    // eslint-disable-next-line no-console
    console.log('Register Error: ', error)
  }
)

http.interceptors.response.use((response) => {
  return response
}, (error) => {
  if (error.response.status === 401) {
    window.location = '/auth'
    Cookies.set('teva-user-token', '')
  }
  return Promise.reject(error)
})

export { http }
