import { http } from '@/plugin/axios'

const state = {
  events: [],
  eventInDay: []
}
const getters = {
  getEvents: (state) => state.events,
  getEventInDay: (state) => state.eventInDay
}
const actions = {
  async fetchEvents ({ commit }, date) {
    return await http({
      url: 'frontend/calendar-events?date=' + date,
      method: 'get'
    }).then(({ data }) => {
      commit('setEvents', data)
    })
  },
  async fetchEventByDate ({ commit }, date) {
    return await http({
      url: 'frontend/events-to-date?date=' + date,
      method: 'get'
    }).then(({ data }) => {
      commit('setEventInDate', data)
    })
  },
  async fetchSpecialEvent ({ commit }) {
    return await http({
      url: 'frontend/special-event',
      method: 'get'
    }).then(({ data }) => {
      commit('setEventInDate', data)
    })
  }
}
const mutations = {
  setEvents (state, eventData) {
    state.events = eventData.data
  },
  setEventInDate (state, eventData) {
    state.eventInDay = eventData.data
  }
}

export default {
  namespace: true,
  state,
  getters,
  actions,
  mutations
}
