import { http } from '@/plugin/axios'

const state = {

}
const getters = {

}
const actions = {
  async registerUser ({ commit, dispatch }, userRegistrationData) {
    let phone = userRegistrationData.phone.replaceAll('(', '')
    phone = phone.replaceAll(')', '')
    phone = phone.replaceAll(' ', '')
    phone = phone.replaceAll('-', '')

    const userData = {
      phone: phone,
      fio: userRegistrationData.fio,
      speciality: userRegistrationData.speciality
    }

    try {
      return await http.post('frontend/register-participant', userData)
    } catch (err) {
      console.error(`Ошибка при регистрации пользователя: ${err.message}`)
      return err.response.data
    }
  }
}
const mutations = {

}

export default {
  namespace: true,
  state,
  getters,
  actions,
  mutations
}
