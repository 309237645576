<template>
  <div class="content">

    <div class="container inside-container">

      <h1 class="b-title">
        Ошибка 404
      </h1>

      <a href="#" class="error-page-block">
        <div class="title">
          Извините, мы не можем найти такую страницу
        </div>
        <span>Перейти на главную</span>
      </a>

      <div class="content-text text-size-middle">

        <h1 class="b-title">
          Прошедшие вебинары, видеолекции, мастер-классы
        </h1>

        <div class="event-slider-section">
          <div class="swiper-container">
            <div class="swiper-wrapper">
              <div class="swiper-slide">
                <a href="#" class="event-info-block">
                  <div class="pic" style="background: url(img/slide-pic1.png) center center no-repeat; background-size: cover;"></div>
                  <div class="desc type1">
                    <div class="theme">Конференция</div>
                    <div class="text">5-я ежегодная конференция «ВЕЙНОВСКИЕ ЧТЕНИЯ»</div>
                    <div class="date">28 февраля, 2019</div>
                  </div>
                </a>
              </div>
              <div class="swiper-slide">
                <a href="#" class="event-info-block">
                  <div class="pic" style="background: url(img/slide-pic2.png) center center no-repeat; background-size: cover;"></div>
                  <div class="desc type2">
                    <div class="theme">Вебинары</div>
                    <div class="text">Серия вебинаров «Мигрень от А до Я», с участием международных и российских экспертов</div>
                    <div class="date">28 февраля, 2019</div>
                  </div>
                </a>
              </div>
              <div class="swiper-slide">
                <a href="#" class="event-info-block">
                  <div class="pic" style="background: url(img/slide-pic3.png) center center no-repeat; background-size: cover;"></div>
                  <div class="desc type3">
                    <div class="theme">Лекции</div>
                    <div class="text">Международный междисциплинарный конгресс «MANAGE PAIN»</div>
                    <div class="date">28 февраля, 2019</div>
                  </div>
                </a>
              </div>
            </div>
            <!-- Add Pagination -->
            <div class="swiper-button-next"></div>
            <div class="swiper-button-prev"></div>

            <a href="#" class="arrow-link">Все лекции</a>
          </div>
        </div>

      </div>

    </div>

  </div>
</template>

<script>
export default {
  name: 'PageNotFound'
}
</script>
