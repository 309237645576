import Vue from 'vue'
import VueRouter from 'vue-router'
import PageNotFound from '@/views/PageNotFound'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import(/* webpackChunkName: "home" */ '../views/Home.vue')
  },
  {
    path: '/headaches',
    name: 'Headaches',
    component: () => import(/* webpackChunkName: "headaches" */ '../views/Headaches.vue')
  },
  {
    path: '/migraine-pathogenesis',
    name: 'MigrainePathogenesis',
    component: () => import(/* webpackChunkName: "migraine-pathogenesis" */ '../views/MigrainePathogenesis.vue')
  },
  {
    path: '/clinical-manifestations',
    name: 'ClinicalManifestations',
    component: () => import(/* webpackChunkName: "clinical-manifestations" */ '../views/ClinicalManifestations.vue')
  },
  {
    path: '/diagnostics',
    name: 'Diagnostics',
    component: () => import(/* webpackChunkName: "diagnostics" */ '../views/Diagnostics.vue')
  },
  {
    path: '/living-with-migraines',
    name: 'LivingWithMigraines',
    component: () => import(/* webpackChunkName: "living-with-migraines" */ '../views/LivingWithMigraines.vue')
  },
  {
    path: '/therapy',
    name: 'Therapy',
    component: () => import(/* webpackChunkName: "therapy" */ '../views/Therapy.vue')
  },
  {
    path: '/training',
    name: 'Trainings',
    component: () => import(/* webpackChunkName: "training" */ '../views/Training.vue')
  },
  {
    path: '/training/:id',
    name: 'Training',
    component: () => import(/* webpackChunkName: "training-template" */ '../views/TrainingTemplate.vue')
  },
  {
    path: '/privacy',
    name: 'PrivacyPolicy',
    component: () => import(/* webpackChunkName: "training" */ '../views/PrivacyPolicy.vue')
  },
  {
    path: '/statement',
    name: 'Statement',
    component: () => import(/* webpackChunkName: "training" */ '../views/Statement.vue')
  },
  {
    path: '/terms-of-use',
    name: 'TermsOfUse',
    component: () => import(/* webpackChunkName: "training" */ '../views/TermsOfUse.vue')
  },
  {
    path: '/first-webinar',
    name: 'FirstWebinar',
    component: () => import(/* webpackChunkName: "webinars" */ '../views/Webinars/FirstWebinar.vue')
  },
  {
    path: '/about',
    name: 'About',
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '*',
    component: PageNotFound
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior: () => ({
    y: 0
  }),
  routes
})

export default router
